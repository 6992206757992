<template>
  <main>
    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4" v-if="company">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'companies' }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link tag="li" :to="{ name: 'company-rights', params: { cid } }"><a>{{ $t('breadcrumb_company_rights') }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <section class="px-2 md:px-0 py-4" v-if="company">
      <div class="container">
        <div class="columns">
          <div class="column is-12-mobile is-4-tablet is-3-desktop">
            <sqr-input-number
              label="company_rights_week_year"
              v-model="weekYear"
              @change="load()"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="px-2 md:px-0 py-4" v-if="org">
      <div class="container">
        <div v-for="item in org.content" :key="item.id">
          <template v-if="item.type === 'team'">
            <div
              class="mt-8 p-4 -mx-4 mb-4 border-b border-gray-500 bg-gray-100"
            >
              <h1 class="title is-3">{{ item.name }}</h1>
            </div>
          </template>
          <template v-if="item.type === 'account'">
            <div class="py-2 border-b">
              <div class="columns is-mobile is-multiline">
                <div class="column is-6-mobile is-3-tablet is-3-desktop">
                  <div class="field">
                    <label class="label">{{
                      $t('company_rights_account')
                    }}</label>
                    <div class="input is-static">
                      <span class="mr-1">{{ item.givenName }}</span>
                      <span class="font-medium">{{ item.familyName }}</span>
                    </div>
                  </div>
                </div>
                <template v-if="loading[item.id]">
                  <div class="column">
                    <sqr-progress />
                  </div>
                </template>
                <template v-else-if="loadError[item.id]">
                  <div class="column">
                    <sqr-error-note :error="loadError[item.id]" />
                  </div>
                </template>
                <template v-else-if="docs[item.id]">
                  <div class="column is-6-mobile is-3-tablet is-2-desktop">
                    <sqr-input-number
                      label="company_rights_previous"
                      :value="docs[item.id].vacationsPrevious"
                      @change="
                        fieldSet({
                          aid: item.id,
                          field: 'vacationsPrevious',
                          value: $event
                        })
                      "
                      :disabled="disabled"
                    />
                  </div>
                  <div class="column is-6-mobile is-3-tablet is-2-desktop">
                    <sqr-input-number
                      label="company_rights_contract"
                      :value="docs[item.id].vacationsByContract"
                      @change="
                        fieldSet({
                          aid: item.id,
                          field: 'vacationsByContract',
                          value: $event
                        })
                      "
                      :disabled="disabled"
                    />
                  </div>
                  <div class="column is-6-mobile is-3-tablet is-2-desktop">
                    <sqr-input-number
                      label="company_rights_total"
                      :value="docs[item.id].vacations"
                      :disabled="true"
                    />
                  </div>
                  <div
                    class="column is-6-mobile is-3-tablet is-1-desktop"
                    v-show="saving[item.id]"
                  >
                    <sqr-progress label="progress_saving" />
                  </div>
                  <div
                    class="column is-12-mobile is-12-tablet is-12-desktop"
                    v-show="saveError[item.id]"
                  >
                    <sqr-error-note :error="saveError[item.id]" />
                  </div>
                  <div
                    class="column is--mobile is-4-tablet is-3-desktop"
                    v-show="!saving[item.id] && !saveError[item.id]"
                  >
                    <update-info :value="docs[item.id]" />
                  </div>
                </template>
                <template v-else>
                  <router-link
                    :to="{ name: 'right-add', params: { cid, aid: item.id } }"
                  >
                    {{ $t('company_rights_new') }}
                  </router-link>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// TODO create right when missing

import { mapState, mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import { fromPairs, clone } from 'ramda';
import buildRef from '@/utils/buildRef';

import company from './company';

import SqrProgress from '@/sqrd/SqrProgress';
import SqrErrorNote from '@/sqrd/SqrErrorNote';
import SqrInputNumber from '@/sqrd/SqrInputNumber';

import CompanyName from '@/components/CompanyName';
import UpdateInfo from '@/components/UpdateInfo';

export default {
  name: 'CompanyRights',
  mixins: [company],
  components: {
    SqrProgress,
    SqrErrorNote,
    SqrInputNumber,
    CompanyName,
    UpdateInfo
  },
  computed: {
    ...mapState('org', { org: 'record' }),
    ...mapState('companyRights', ['pathes', 'docs', 'loading', 'loadError']),
    ...mapGetters('auth', ['uid', 'displayName']),
    ...mapGetters('perms', ['isManagerRW']),
    disabled() {
      return !this.isManagerRW;
    }
  },
  data() {
    return {
      weekYear: DateTime.local().weekYear,
      saving: {},
      saveError: {}
    };
  },
  mounted() {
    this.load();
  },
  beforeDestroy() {
    this.unsub();
  },
  methods: {
    async load() {
      const weekYear = this.weekYear;
      if (weekYear < 2000) return;

      try {
        const org = await this.orgSub({
          path: ['companies', this.cid, 'orgs'],
          id: 'default'
        });

        const pairs = org.content
          .filter(item => item.type === 'account')
          .map(({ id }) => [
            id,
            ['companies', this.cid, 'accounts', id, 'rights', `${weekYear}`]
          ]);
        const pathes = fromPairs(pairs);
        this.sub({ pathes });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    ...mapActions('company', ['fieldSet']),
    ...mapActions('org', { orgSub: 'sub' }),
    ...mapActions('companyRights', ['sub', 'unsub']),
    async fieldSet({ aid, field, value }) {
      try {
        this.$set(this.saving, aid, true);
        this.$set(this.saveError, aid, undefined);
        const right = clone(this.docs[aid]);
        this.$set(right, field, value);
        const vacations =
          Math.round(
            (right.vacationsPrevious ?? 0) * 100 +
              (right.vacationsByContract ?? 0) * 100
          ) / 100;
        this.$set(right, 'vacations', vacations);
        this.$set(right, 'updated', DateTime.utc().toISO());
        this.$set(right, 'updatedByUid', this.uid);
        this.$set(right, 'updatedByName', this.displayName ?? this.uid);
        const path = this.pathes[aid];
        const ref = buildRef(path);
        return await ref.set(right, { merge: true });
      } catch (error) {
        this.$set(this.save, aid, error);
        return Promise.reject(error);
      } finally {
        this.$set(this.saving, aid, undefined);
      }
    }
  }
};
</script>
